import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

export default defineNuxtPlugin(() => {


    const config = useRuntimeConfig();

    const pusher = Pusher;

    const tenantStore = useTenantStore();


    let token = null;
    let tenant = null;

    if(tenantStore.getTenantId.trimStart() !== '' || tenantStore.getToken.trimStart() !== '')
    {
        tenant = toRaw(tenantStore.getTenantId);
        token = 'Bearer ' + toRaw(tenantStore.getToken);
    }

    const echo = new Echo({
        broadcaster: 'pusher',
        key: 'maintainer',
        wsHost: config.public.baseUrlWebsocket,
        wsPort: 6004,
        wssPort:6004,
        cluster: 'api',
        forceTLS: true,
        encrypted: true,
        enabledTransports: ['ws','wss'],
        disableStats: true,
        authEndpoint: config.public.baseUrlAuthWebsockets,
        auth : {
            headers : {
                Authorization: token,
                Accept: "application/json",
                'X-Tenant': tenant,
            }
        },
    })

    return {
        provide:{
            echo:echo,
            pusher:pusher,
        }
    }
})