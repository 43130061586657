import PrimeVue from "primevue/config";
import Dropdown from 'primevue/dropdown';
import ToastService from 'primevue/toastservice';
import Toast from "primevue/toast";
import Tooltip from "primevue/tooltip";
import Paginator from 'primevue/paginator';
import Breadcrumb from "primevue/breadcrumb";
import Dialog from 'primevue/dialog';
import Tree from "primevue/tree";
import Calendar from "primevue/calendar";
import CascadeSelect from "primevue/cascadeselect";
import InputMask from "primevue/inputmask";
import Slider from "primevue/slider";
import InputText from "primevue/inputtext";
import InputNumber from 'primevue/inputnumber';
import MultiSelect from 'primevue/multiselect';
import Textarea from 'primevue/textarea';
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import FileUpload from 'primevue/fileupload';
import Timeline from "primevue/timeline";
import Carousel from 'primevue/carousel';
import Image from 'primevue/image';
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Steps from 'primevue/steps';
import RadioButton from 'primevue/radiobutton';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Checkbox from 'primevue/checkbox';
import Galleria from "primevue/galleria";
import Menu from 'primevue/menu';
import InputSwitch from 'primevue/inputswitch';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import TreeSelect from "primevue/treeselect";
import TriStateCheckbox from "primevue/tristatecheckbox";
import Button from "primevue/button";
import ColorPicker from "primevue/colorpicker";
import ConfirmDialog from 'primevue/confirmdialog';
import Skeleton from "primevue/skeleton";
import OverlayPanel from 'primevue/overlaypanel';
import Chip from "primevue/chip";
import InputOtp from 'primevue/inputotp';
import Stepper from "primevue/stepper";
import StepperPanel from "primevue/stepperpanel";
import ProgressSpinner from "primevue/progressspinner";
import Row from "primevue/row";
import ColumnGroup from "primevue/columngroup";
import Sidebar from "primevue/sidebar";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(PrimeVue, {
        ripple: true,
        locale: {
            emptyMessage: 'Нет данных',
            dayNames: ["воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"],
            dayNamesShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
            dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
            monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
            monthNamesShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
            today: 'Сегодня',
            clear: 'Очистить',
            weekHeader: 'Нед.',
            firstDayOfWeek: 0,
            dateFormat: 'mm/dd/yy',
            fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        }
    });
    nuxtApp.vueApp.use(ToastService);
    nuxtApp.vueApp.component("Dropdown", Dropdown);
    nuxtApp.vueApp.directive('tooltip', Tooltip);
    nuxtApp.vueApp.component('Breadcrumb', Breadcrumb);
    nuxtApp.vueApp.component('Dialog', Dialog);
    nuxtApp.vueApp.component('Tree', Tree);
    nuxtApp.vueApp.component('Paginator', Paginator);
    nuxtApp.vueApp.component("Toast", Toast);
    nuxtApp.vueApp.component('Calendar', Calendar)
    nuxtApp.vueApp.component('CascadeSelect', CascadeSelect)
    nuxtApp.vueApp.component('Slider', Slider)
    nuxtApp.vueApp.component('InputText', InputText)
    nuxtApp.vueApp.component('InputNumber', InputNumber)
    nuxtApp.vueApp.component('MultiSelect', MultiSelect)
    nuxtApp.vueApp.component('Row',Row)
    nuxtApp.vueApp.component('ColumnGroup',ColumnGroup)
    nuxtApp.vueApp.component('Textarea', Textarea)
    nuxtApp.vueApp.component('TabView', TabView)
    nuxtApp.vueApp.component('TabPanel', TabPanel)
    nuxtApp.vueApp.component('FileUpload', FileUpload)
    nuxtApp.vueApp.component('Timeline', Timeline)
    nuxtApp.vueApp.component('InputMask', InputMask)
    nuxtApp.vueApp.component('Carousel', Carousel)
    nuxtApp.vueApp.component('Image', Image)
    nuxtApp.vueApp.component('Accordion', Accordion)
    nuxtApp.vueApp.component('AccordionTab', AccordionTab)
    nuxtApp.vueApp.component('Steps', Steps)
    nuxtApp.vueApp.component('RadioButton', RadioButton)
    nuxtApp.vueApp.component('Column', Column)
    nuxtApp.vueApp.component('DataTable', DataTable)
    nuxtApp.vueApp.component('Checkbox', Checkbox)
    nuxtApp.vueApp.component('Galleria', Galleria)
    nuxtApp.vueApp.component('Menu', Menu)
    nuxtApp.vueApp.component('InputSwitch', InputSwitch)
    nuxtApp.vueApp.component('ConfirmPopup', ConfirmPopup)
    nuxtApp.vueApp.component('TreeSelect', TreeSelect)
    nuxtApp.vueApp.component('TriStateCheckbox', TriStateCheckbox)
    nuxtApp.vueApp.component('Button', Button)
    nuxtApp.vueApp.component('ColorPicker', ColorPicker)
    nuxtApp.vueApp.component('ConfirmDialog', ConfirmDialog)
    nuxtApp.vueApp.component("OverlayPanel", OverlayPanel);
    nuxtApp.vueApp.component('Skeleton',Skeleton)
    nuxtApp.vueApp.component('Chip',Chip)
    nuxtApp.vueApp.component('InputOtp',InputOtp)
    nuxtApp.vueApp.component('Stepper',Stepper)
    nuxtApp.vueApp.component('StepperPanel',StepperPanel)
    nuxtApp.vueApp.component('ProgressSpinner',ProgressSpinner)
    nuxtApp.vueApp.component('Sidebar',Sidebar)

    nuxtApp.vueApp.use(ConfirmationService)

    nuxtApp.vueApp.directive('tooltip', Tooltip)
});