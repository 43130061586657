import validate from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_oep56pqnvk3i6t2w5lg7saggaq/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_oep56pqnvk3i6t2w5lg7saggaq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/lwjal/projects/ained_developer_front/middleware/auth.js"),
  "auth-tenant": () => import("/home/lwjal/projects/ained_developer_front/middleware/authTenant.js"),
  "broker-client": () => import("/home/lwjal/projects/ained_developer_front/middleware/broker.client.js"),
  callable: () => import("/home/lwjal/projects/ained_developer_front/middleware/callable.js"),
  ghost: () => import("/home/lwjal/projects/ained_developer_front/middleware/ghost.js"),
  "ghost-tenant": () => import("/home/lwjal/projects/ained_developer_front/middleware/ghostTenant.js")
}