import { default as callableoq8whq5YptMeta } from "/home/lwjal/projects/ained_developer_front/pages/callable.vue?macro=true";
import { default as indexHEuIunswmoMeta } from "/home/lwjal/projects/ained_developer_front/pages/constructor-forge/index.vue?macro=true";
import { default as indexTFdAXVwZ3nMeta } from "/home/lwjal/projects/ained_developer_front/pages/constructor/index.vue?macro=true";
import { default as clientsZBHC0KMhdKMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/clients.vue?macro=true";
import { default as contracts44ETnmYfaqMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/contracts.vue?macro=true";
import { default as indexNQw9hNnyPkMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/controls/constructor/index.vue?macro=true";
import { default as indexGNj5aOAuTPMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/controls/export/index.vue?macro=true";
import { default as exportvKgx58pSGcMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/controls/export.vue?macro=true";
import { default as indexHAwCmQoNCFMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/controls/import/index.vue?macro=true";
import { default as importr2G97p2zXnMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/controls/import.vue?macro=true";
import { default as indexFGaBOdBGu5Meta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/controls/index.vue?macro=true";
import { default as controlsCSOD65oYg8Meta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/controls.vue?macro=true";
import { default as dealsEOBgLyJsXhMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/deals.vue?macro=true";
import { default as _91id_93zhTJy1sMe1Meta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/decorating/[id].vue?macro=true";
import { default as indexmgSjw53TiyMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/decorating/index.vue?macro=true";
import { default as desktop988A2AHnI4Meta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/desktop.vue?macro=true";
import { default as indexIpwW9WOvxiMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/index.vue?macro=true";
import { default as indexDw4zSny55HMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/index.vue?macro=true";
import { default as indexvqpWyF6To8Meta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/openapi/index.vue?macro=true";
import { default as openapiNG6AEPm91RMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/openapi.vue?macro=true";
import { default as _91id_93eVVPn6i3jKMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets/[id].vue?macro=true";
import { default as dumpsZ4Efxxow0sMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets/histories/[id]/dumps.vue?macro=true";
import { default as index1D0VFfW2TvMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets/histories/[id]/index.vue?macro=true";
import { default as _91id_93v6M8RjfOyrMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets/histories/[id].vue?macro=true";
import { default as historiesWoJleIsPVsMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets/histories.vue?macro=true";
import { default as indexFuH3bAP3hnMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets/index.vue?macro=true";
import { default as widgetshDjMS1fbqFMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets.vue?macro=true";
import { default as modulesijeRUOpfjpMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/modules.vue?macro=true";
import { default as _91id_93T6qL1Hw36eMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/objects/[id].vue?macro=true";
import { default as indexVfgMMlHdAuMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/objects/index.vue?macro=true";
import { default as objectsN7AYKLF9PYMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/objects.vue?macro=true";
import { default as _91id_936PcuRtFmIRMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/progress/[id].vue?macro=true";
import { default as _91id_93backRpcIymNaVYMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/progress/[id]back.vue?macro=true";
import { default as createProgress2xJzP48y66Meta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/progress/createProgress.vue?macro=true";
import { default as index4nqaRoT9iqMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/progress/index.vue?macro=true";
import { default as rolesKkywTJlGgDMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/roles.vue?macro=true";
import { default as indexiUFac7aZBaMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/settings/index.vue?macro=true";
import { default as _91id_93GUWJv4fb8dMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/stocks/[id].vue?macro=true";
import { default as index1fyYDdxjilMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/stocks/index.vue?macro=true";
import { default as stocksQo4NfiCwsNMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/stocks.vue?macro=true";
import { default as tasksW8iZeFME6cMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/tasks.vue?macro=true";
import { default as usersCblUZLhbXnMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard/users.vue?macro=true";
import { default as dashboardBvWDyvCWkrMeta } from "/home/lwjal/projects/ained_developer_front/pages/dashboard.vue?macro=true";
import { default as indexXuRnst8CadMeta } from "/home/lwjal/projects/ained_developer_front/pages/index.vue?macro=true";
import { default as invitesoLxoljEKwlMeta } from "/home/lwjal/projects/ained_developer_front/pages/invites.vue?macro=true";
import { default as indexERDH3F7bxWMeta } from "/home/lwjal/projects/ained_developer_front/pages/visual_polygon/facade/index.vue?macro=true";
import { default as facadeMnopn7S65oMeta } from "/home/lwjal/projects/ained_developer_front/pages/visual_polygon/facade.vue?macro=true";
import { default as indexkSdlDbW7O9Meta } from "/home/lwjal/projects/ained_developer_front/pages/visual_polygon/floors/index.vue?macro=true";
import { default as floorsSwz20SIpG9Meta } from "/home/lwjal/projects/ained_developer_front/pages/visual_polygon/floors.vue?macro=true";
import { default as indexGkHq3lD2tvMeta } from "/home/lwjal/projects/ained_developer_front/pages/visual_polygon/genplan/index.vue?macro=true";
import { default as genplanXR81WnFGHYMeta } from "/home/lwjal/projects/ained_developer_front/pages/visual_polygon/genplan.vue?macro=true";
import { default as visual_polygontgh7w6uFeRMeta } from "/home/lwjal/projects/ained_developer_front/pages/visual_polygon.vue?macro=true";
export default [
  {
    name: "callable",
    path: "/callable",
    meta: callableoq8whq5YptMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/callable.vue")
  },
  {
    name: "constructor-forge",
    path: "/constructor-forge",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/constructor-forge/index.vue")
  },
  {
    name: "constructor",
    path: "/constructor",
    meta: indexTFdAXVwZ3nMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/constructor/index.vue")
  },
  {
    name: dashboardBvWDyvCWkrMeta?.name,
    path: "/dashboard",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-clients",
    path: "clients",
    meta: clientsZBHC0KMhdKMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/clients.vue")
  },
  {
    name: "dashboard-contracts",
    path: "contracts",
    meta: contracts44ETnmYfaqMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/contracts.vue")
  },
  {
    name: controlsCSOD65oYg8Meta?.name,
    path: "controls",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/controls.vue"),
    children: [
  {
    name: "dashboard-controls-constructor",
    path: "constructor",
    meta: indexNQw9hNnyPkMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/controls/constructor/index.vue")
  },
  {
    name: exportvKgx58pSGcMeta?.name,
    path: "export",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/controls/export.vue"),
    children: [
  {
    name: "dashboard-controls-export",
    path: "",
    meta: indexGNj5aOAuTPMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/controls/export/index.vue")
  }
]
  },
  {
    name: importr2G97p2zXnMeta?.name,
    path: "import",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/controls/import.vue"),
    children: [
  {
    name: "dashboard-controls-import",
    path: "",
    meta: indexHAwCmQoNCFMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/controls/import/index.vue")
  }
]
  },
  {
    name: "dashboard-controls",
    path: "",
    meta: indexFGaBOdBGu5Meta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/controls/index.vue")
  }
]
  },
  {
    name: "dashboard-deals",
    path: "deals",
    meta: dealsEOBgLyJsXhMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/deals.vue")
  },
  {
    name: "dashboard-decorating-id",
    path: "decorating/:id()",
    meta: _91id_93zhTJy1sMe1Meta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/decorating/[id].vue")
  },
  {
    name: "dashboard-decorating",
    path: "decorating",
    meta: indexmgSjw53TiyMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/decorating/index.vue")
  },
  {
    name: "dashboard-desktop",
    path: "desktop",
    meta: desktop988A2AHnI4Meta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/desktop.vue")
  },
  {
    name: "dashboard",
    path: "",
    meta: indexIpwW9WOvxiMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/index.vue")
  },
  {
    name: modulesijeRUOpfjpMeta?.name,
    path: "modules",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/modules.vue"),
    children: [
  {
    name: "dashboard-modules",
    path: "",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/index.vue")
  },
  {
    name: openapiNG6AEPm91RMeta?.name,
    path: "openapi",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/openapi.vue"),
    children: [
  {
    name: "dashboard-modules-openapi",
    path: "",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/openapi/index.vue")
  }
]
  },
  {
    name: widgetshDjMS1fbqFMeta?.name,
    path: "widgets",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets.vue"),
    children: [
  {
    name: "dashboard-modules-widgets-id",
    path: ":id()",
    meta: _91id_93eVVPn6i3jKMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets/[id].vue")
  },
  {
    name: "dashboard-modules-widgets-histories",
    path: "histories",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets/histories.vue"),
    children: [
  {
    name: _91id_93v6M8RjfOyrMeta?.name,
    path: ":id()",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets/histories/[id].vue"),
    children: [
  {
    name: "dashboard-modules-widgets-histories-id-dumps",
    path: "dumps",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets/histories/[id]/dumps.vue")
  },
  {
    name: "dashboard-modules-widgets-histories-id",
    path: "",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets/histories/[id]/index.vue")
  }
]
  }
]
  },
  {
    name: "dashboard-modules-widgets",
    path: "",
    meta: indexFuH3bAP3hnMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/modules/widgets/index.vue")
  }
]
  }
]
  },
  {
    name: objectsN7AYKLF9PYMeta?.name,
    path: "objects",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/objects.vue"),
    children: [
  {
    name: "dashboard-objects-id",
    path: ":id()",
    meta: _91id_93T6qL1Hw36eMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/objects/[id].vue")
  },
  {
    name: "dashboard-objects",
    path: "",
    meta: indexVfgMMlHdAuMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/objects/index.vue")
  }
]
  },
  {
    name: "dashboard-progress-id",
    path: "progress/:id()",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/progress/[id].vue")
  },
  {
    name: "dashboard-progress-idback",
    path: "progress/:id()back",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/progress/[id]back.vue")
  },
  {
    name: "dashboard-progress-createProgress",
    path: "progress/createProgress",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/progress/createProgress.vue")
  },
  {
    name: "dashboard-progress",
    path: "progress",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/progress/index.vue")
  },
  {
    name: "dashboard-roles",
    path: "roles",
    meta: rolesKkywTJlGgDMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/roles.vue")
  },
  {
    name: "dashboard-settings",
    path: "settings",
    meta: indexiUFac7aZBaMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/settings/index.vue")
  },
  {
    name: stocksQo4NfiCwsNMeta?.name,
    path: "stocks",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/stocks.vue"),
    children: [
  {
    name: "dashboard-stocks-id",
    path: ":id()",
    meta: _91id_93GUWJv4fb8dMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/stocks/[id].vue")
  },
  {
    name: "dashboard-stocks",
    path: "",
    meta: index1fyYDdxjilMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/stocks/index.vue")
  }
]
  },
  {
    name: "dashboard-tasks",
    path: "tasks",
    meta: tasksW8iZeFME6cMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/tasks.vue")
  },
  {
    name: "dashboard-users",
    path: "users",
    meta: usersCblUZLhbXnMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/dashboard/users.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexXuRnst8CadMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/index.vue")
  },
  {
    name: "invites",
    path: "/invites",
    meta: invitesoLxoljEKwlMeta || {},
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/invites.vue")
  },
  {
    name: "visual_polygon",
    path: "/visual_polygon",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/visual_polygon.vue"),
    children: [
  {
    name: facadeMnopn7S65oMeta?.name,
    path: "facade",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/visual_polygon/facade.vue"),
    children: [
  {
    name: "visual_polygon-facade",
    path: "",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/visual_polygon/facade/index.vue")
  }
]
  },
  {
    name: floorsSwz20SIpG9Meta?.name,
    path: "floors",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/visual_polygon/floors.vue"),
    children: [
  {
    name: "visual_polygon-floors",
    path: "",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/visual_polygon/floors/index.vue")
  }
]
  },
  {
    name: genplanXR81WnFGHYMeta?.name,
    path: "genplan",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/visual_polygon/genplan.vue"),
    children: [
  {
    name: "visual_polygon-genplan",
    path: "",
    component: () => import("/home/lwjal/projects/ained_developer_front/pages/visual_polygon/genplan/index.vue")
  }
]
  }
]
  }
]