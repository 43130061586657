export const useTenantStore = defineStore('tenant_store', {
    state: () => (
        {
            isAuth: false,
            tenant_url:'',
            tenant_id:'',
            tenant_url_path:'',
            user:{},
            _token:'',
            component_name:'',
            component_date:'',
            product_name:'',
            component_id:'',
            condition: '',
            isLoading:true,
        }
    ),
    getters: {
        getAuth: (state) => {
            return state.isAuth;
        },
        getTenantUrl: (state) => {
            return state.tenant_url;
        },

        getTenantId: (state) => {
            return state.tenant_id;
        },
        getTenantUrlPath: (state) => {
            return state.tenant_url_path;
        },

        getUser: (state) => {
            return state.user;
        },

        getToken: (state) => {
            return state._token;
        },
        getComponentName: (state) => {
            return state.component_name;
        },
        getComponentDate: (state) => {
            return state.component_date;
        },
        getComponentId: (state) => {
            return state.component_id;
        },
        getCondition: (state) => {
            return state.condition;
        },
        getProductName: (state) => {
            return state.product_name
        },
        getIsloading: state => state.isLoading,
    },

    actions: {
        login(tenant_url, tenant_id, tenant_url_path, user, _token, component_name, component_date) {
            this.isAuth = true;
            this.tenant_url = tenant_url;
            this.tenant_id = tenant_id;
            this.tenant_url_path = tenant_url_path;
            this.user = user;
            this._token = _token;
            this.component_name = component_name;
            this.component_date = component_date;
        },

        setTenantUrl(tenant_url) {
            this.tenant_url = tenant_url;
        },

        setTenantId(tenant_id) {
            this.tenant_id = tenant_id;
        },

        setTenantUrlPath(tenant_url_path) {
            this.tenant_url_path = tenant_url_path;
        },

        setLoading(val) {
            this.isLoading = val;
        },

        setComponentName(component_name) {
            this.component_name = component_name;
        },

        setComponentDate(component_date) {
            this.component_date = component_date;
        },

        setComponentId(component_id) {
            this.component_id = component_id;
        },

        setCondition(condition) {
            this.condition = condition;
        },
        setProductName(val) {
            this.product_name = val;
        },
        setUser(user) {
            this.user = user;
        },
        logout() {
            this.isAuth = false;
            this.tenant_url = '';
            this.tenant_id = '';
            this.user = {};
            this._token = '';
            this.component_name = '';
            this.component_date = '';
            this.component_id = '';
            this.condition = '';
        },

        isThatRole(roleName) {
            //(~-~)//

            return this.user?.role?.name === roleName;
        }
    },

    persist:{
        storage:persistedState.cookies
    },

})