import { plugin as VueTippy } from 'vue-tippy'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(VueTippy, {
        arrow: true,
        directive: 'tippy', // => v-tippy
        component: 'tippy', // => <tippy/>
        componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
        defaultProps: {
          theme: 'dark',
          placement: 'auto',
          allowHTML: true,
          delay: 100,
          animation: 'shift-toward-subtle',
          appendTo: () => document.querySelector('body')
        },
    });
});