import VueLazyload from "vue3-lazyload";


export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(VueLazyload, {
        listenEvents: [ 'scroll' ],
        preLoad: 1.3,
        observer: true,
        observerOptions: {
            rootMargin: '0px',
            threshold: 0.1
        },
        lazyComponent: true
    })
});