// plugins/fastclick.js
export default defineNuxtPlugin(nuxtApp => {
    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let clickTimeout = null;

    // Function to handle mouse down events
    const handleMouseDown = (event) => {
        isDragging = false;
        startX = event.clientX;
        startY = event.clientY;

        clickTimeout = setTimeout(() => {
            isDragging = true;
        }, 200);
    };

    // Function to handle mouse up events
    const handleMouseUp = (event) => {
        clearTimeout(clickTimeout);

        const deltaX = Math.abs(event.clientX - startX);
        const deltaY = Math.abs(event.clientY - startY);

        if (deltaX > 10 || deltaY > 10) {
            isDragging = true;
        } else {
            isDragging = false;
        }
    };

    // Function to handle click events
    const handleClick = (event) => {
        if (!isDragging) {
            const customEvent = new CustomEvent("fastclick", {
                detail: { originalEvent: event },
            });
            console.log('Dispatching fastclick event');
            event.target.dispatchEvent(customEvent);
        }
    };

    // Create a directive to bind the event listeners
    nuxtApp.vueApp.directive('fastclick', {
        mounted(el) {
            console.log('Binding fastclick directive');
            el.addEventListener("mousedown", handleMouseDown);
            el.addEventListener("mouseup", handleMouseUp);
            el.addEventListener("click", handleClick);
        },
        unmounted(el) {
            console.log('Unbinding fastclick directive');
            el.removeEventListener("mousedown", handleMouseDown);
            el.removeEventListener("mouseup", handleMouseUp);
            el.removeEventListener("click", handleClick);
        }
    });
});
