import revive_payload_client_kJdABZO2mh from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_oep56pqnvk3i6t2w5lg7saggaq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1jLqth65Xf from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_oep56pqnvk3i6t2w5lg7saggaq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_q6hrF41oh5 from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_oep56pqnvk3i6t2w5lg7saggaq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_k6IBQzj4k4 from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_oep56pqnvk3i6t2w5lg7saggaq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_liJI83iYzb from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_oep56pqnvk3i6t2w5lg7saggaq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dx6ar4YgJJ from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_oep56pqnvk3i6t2w5lg7saggaq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bMEgLNxZGN from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_oep56pqnvk3i6t2w5lg7saggaq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZVpcc2TzG2 from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/lwjal/projects/ained_developer_front/.nuxt/components.plugin.mjs";
import prefetch_client_r30p52wkmj from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_oep56pqnvk3i6t2w5lg7saggaq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_GnHq7BsqN9 from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._37kn7k5ph53rw2k6wqvuhkf6fy/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/lwjal/projects/ained_developer_front/.nuxt/sentry-client-config.mjs";
import plugin_tpcSXoCZlv from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/@wgr-sa+nuxt-panzoom@0.1.0_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@wgr-sa/nuxt-panzoom/dist/runtime/plugin.mjs";
import gsapPlugin_HlwIIyl85L from "/home/lwjal/projects/ained_developer_front/.nuxt/gsapPlugin.mjs";
import plugin_wy0B721ODc from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_EbELCf3CCh from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0GX2wNXaPc from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_rS5xb60Z0v from "/home/lwjal/projects/ained_developer_front/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typesc_n7tokq7p5orfvfqa7nvbtjgnzi/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import laravel_echo_client_RbVOyfQxz3 from "/home/lwjal/projects/ained_developer_front/plugins/laravel-echo.client.js";
import interact_AhQ3pEZVYm from "/home/lwjal/projects/ained_developer_front/plugins/interact.js";
import fastClick_l0KjpKt4Mm from "/home/lwjal/projects/ained_developer_front/plugins/fastClick.js";
import Vue3Marquee_client_r0z0zv1iLW from "/home/lwjal/projects/ained_developer_front/plugins/Vue3Marquee.client.ts";
import captchaGun_client_WlMqDSf2Fj from "/home/lwjal/projects/ained_developer_front/plugins/captchaGun.client.js";
import confity_client_P74xOOkpNT from "/home/lwjal/projects/ained_developer_front/plugins/confity.client.js";
import contextLocale_global_2qdbfBWRT6 from "/home/lwjal/projects/ained_developer_front/plugins/contextLocale.global.js";
import editor_client_PuwCzrQ8DT from "/home/lwjal/projects/ained_developer_front/plugins/editor.client.js";
import eventBus_cMx6qAgliS from "/home/lwjal/projects/ained_developer_front/plugins/eventBus.js";
import lazy_loader_HrpFmo0MyI from "/home/lwjal/projects/ained_developer_front/plugins/lazy_loader.js";
import notify_oWSRP5vOkO from "/home/lwjal/projects/ained_developer_front/plugins/notify.ts";
import primevue_TdXjRgL1MA from "/home/lwjal/projects/ained_developer_front/plugins/primevue.js";
import query_builder_client_KppvsIFKrQ from "/home/lwjal/projects/ained_developer_front/plugins/query_builder.client.js";
import tippy_client_mvmOPIGOc8 from "/home/lwjal/projects/ained_developer_front/plugins/tippy.client.js";
import utils_EeQrN1BEbD from "/home/lwjal/projects/ained_developer_front/plugins/utils.js";
import yandex_map_client_fmD8086oYe from "/home/lwjal/projects/ained_developer_front/plugins/yandex_map.client.js";
export default [
  revive_payload_client_kJdABZO2mh,
  unhead_1jLqth65Xf,
  router_q6hrF41oh5,
  payload_client_k6IBQzj4k4,
  navigation_repaint_client_liJI83iYzb,
  check_outdated_build_client_dx6ar4YgJJ,
  chunk_reload_client_bMEgLNxZGN,
  plugin_vue3_ZVpcc2TzG2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_r30p52wkmj,
  sentry_client_GnHq7BsqN9,
  sentry_client_config_o34nk2sJbg,
  plugin_tpcSXoCZlv,
  gsapPlugin_HlwIIyl85L,
  plugin_wy0B721ODc,
  switch_locale_path_ssr_EbELCf3CCh,
  i18n_0GX2wNXaPc,
  plugin_rS5xb60Z0v,
  laravel_echo_client_RbVOyfQxz3,
  interact_AhQ3pEZVYm,
  fastClick_l0KjpKt4Mm,
  Vue3Marquee_client_r0z0zv1iLW,
  captchaGun_client_WlMqDSf2Fj,
  confity_client_P74xOOkpNT,
  contextLocale_global_2qdbfBWRT6,
  editor_client_PuwCzrQ8DT,
  eventBus_cMx6qAgliS,
  lazy_loader_HrpFmo0MyI,
  notify_oWSRP5vOkO,
  primevue_TdXjRgL1MA,
  query_builder_client_KppvsIFKrQ,
  tippy_client_mvmOPIGOc8,
  utils_EeQrN1BEbD,
  yandex_map_client_fmD8086oYe
]