
// @ts-nocheck
import locale__home_lwjal_projects_ained_developer_front_locales_ru_blocks_global_js from "../locales/ru/blocks/global.js";
import locale__home_lwjal_projects_ained_developer_front_locales_ru_blocks_roles_js from "../locales/ru/blocks/roles.js";
import locale__home_lwjal_projects_ained_developer_front_locales_ru_blocks_invites_js from "../locales/ru/blocks/invites.js";
import locale__home_lwjal_projects_ained_developer_front_locales_ru_blocks_imports_js from "../locales/ru/blocks/imports.js";
import locale__home_lwjal_projects_ained_developer_front_locales_ru_blocks_subscription_js from "../locales/ru/blocks/subscription.js";
import locale__home_lwjal_projects_ained_developer_front_locales_ru_blocks_integrations_js from "../locales/ru/blocks/integrations.js";


export const localeCodes =  [
  "ru-RU"
]

export const localeLoaders = {
  "ru-RU": [{ key: "../locales/ru/blocks/global.js", load: () => Promise.resolve(locale__home_lwjal_projects_ained_developer_front_locales_ru_blocks_global_js), cache: true },
{ key: "../locales/ru/blocks/roles.js", load: () => Promise.resolve(locale__home_lwjal_projects_ained_developer_front_locales_ru_blocks_roles_js), cache: true },
{ key: "../locales/ru/blocks/invites.js", load: () => Promise.resolve(locale__home_lwjal_projects_ained_developer_front_locales_ru_blocks_invites_js), cache: true },
{ key: "../locales/ru/blocks/imports.js", load: () => Promise.resolve(locale__home_lwjal_projects_ained_developer_front_locales_ru_blocks_imports_js), cache: true },
{ key: "../locales/ru/blocks/subscription.js", load: () => Promise.resolve(locale__home_lwjal_projects_ained_developer_front_locales_ru_blocks_subscription_js), cache: true },
{ key: "../locales/ru/blocks/integrations.js", load: () => Promise.resolve(locale__home_lwjal_projects_ained_developer_front_locales_ru_blocks_integrations_js), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../plugins/i18n/i18n.config.js?hash=78536351&config=1" /* webpackChunkName: "__plugins_i18n_i18n_config_js_78536351" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./plugins/i18n/i18n.config.js",
  "locales": [
    {
      "code": "ru-RU",
      "name": "Русский",
      "files": [
        "/home/lwjal/projects/ained_developer_front/locales/ru/blocks/global.js",
        "/home/lwjal/projects/ained_developer_front/locales/ru/blocks/roles.js",
        "/home/lwjal/projects/ained_developer_front/locales/ru/blocks/invites.js",
        "/home/lwjal/projects/ained_developer_front/locales/ru/blocks/imports.js",
        "/home/lwjal/projects/ained_developer_front/locales/ru/blocks/subscription.js",
        "/home/lwjal/projects/ained_developer_front/locales/ru/blocks/integrations.js"
      ],
      "language": "ru-RU"
    }
  ],
  "defaultLocale": "ru-RU",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "cache": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "ru-RU",
    "name": "Русский",
    "files": [
      {
        "path": "/home/lwjal/projects/ained_developer_front/locales/ru/blocks/global.js"
      },
      {
        "path": "/home/lwjal/projects/ained_developer_front/locales/ru/blocks/roles.js"
      },
      {
        "path": "/home/lwjal/projects/ained_developer_front/locales/ru/blocks/invites.js"
      },
      {
        "path": "/home/lwjal/projects/ained_developer_front/locales/ru/blocks/imports.js"
      },
      {
        "path": "/home/lwjal/projects/ained_developer_front/locales/ru/blocks/subscription.js"
      },
      {
        "path": "/home/lwjal/projects/ained_developer_front/locales/ru/blocks/integrations.js"
      }
    ],
    "language": "ru-RU"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
